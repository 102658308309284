import { Box, Typography } from '@mui/material';

import { useAuthentication, useRegistrationConfirmation } from '@fllite-fe/shared';
import { Button } from '@fllite-fe/shared/src/components/Button';

const ExpiredToken = () => {
	const { user } = useAuthentication();
	const { sendRegistrationConfirmation, loader } = useRegistrationConfirmation();

	const handleClick = async () => {
		sendRegistrationConfirmation({ id: user.id, data: {} });
	};

	return (
		<Box sx={{ color: 'white', textAlign: 'center' }} width={{ md: '460px' }}>
			<Typography sx={{ fontWeight: 600, fontSize: '32px' }}>Token Expired</Typography>
			<Typography sx={{ fontWeight: 400, fontSize: '16px', my: '10px' }}>
				Sorry, this activation link is expired.
			</Typography>
			<Typography sx={{ fontWeight: 400, fontSize: '16px', mb: '15px' }}>
				Please check your email inbox for another confirmation email, or resend the email using the
				button below.
			</Typography>
			<Button secondary onClick={handleClick} isLoading={loader}>
				Resend confirmation email
			</Button>
		</Box>
	);
};

export default ExpiredToken;
