import styled from '@emotion/styled';
import { Box, CircularProgress, Divider, Link, Stack, Typography } from '@mui/material';
import Image from 'next/image';

import { useAuthentication, useRegistrationConfirmation } from '@fllite-fe/shared';
import { H4, Text } from '@fllite-fe/shared/src/components/Text';

const StyledH4 = styled(H4)`
	padding: 24px;
`;

const Wrapper = styled('div')`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px;

	${({ theme }) => theme.breakpoints.up('md')} {
		width: 100%;
		margin: 120px 0 0;
	}
`;

const Card = styled(Box)`
	background: white;
	max-width: 688px;
	border-radius: 8px;
`;

export const UnconfirmedUserModal = () => {
	const { user } = useAuthentication();
	const { sendRegistrationConfirmation, loader } = useRegistrationConfirmation();

	const handleClick = async () => {
		sendRegistrationConfirmation({ id: user.id, data: {} });
	};

	return (
		<Wrapper>
			<Card>
				<StyledH4 primary>Confirm your email to continue</StyledH4>
				<Divider />
				<Stack justifyContent="center" alignItems="center" p="40px 24px">
					<Stack
						spacing={2}
						direction={{ xs: 'column', md: 'row' }}
						width={{ xs: '100%', md: '90%' }}
						margin="auto"
						mb={2}
					>
						<Box flexShrink={0}>
							<Image src="/assets/confirm_image.svg" width={116} height={116} alt="Confirm" />
						</Box>
						<Box>
							<Box mb="16px">
								<Text bold gray>
									Thanks for signing up. Please confirm your email to continue your booking safely.
								</Text>
							</Box>
							<Box mb="16px">
								<Text small gray>
									We sent a confirmation link with instructions to your email address{' '}
									<strong>{user?.email}</strong>
								</Text>
							</Box>
						</Box>
					</Stack>
					<Box>
						<Typography color="#989A9E" fontWeight={500} fontSize="14px">
							Didn’t receive the email? Check your spam folder or{' '}
							{loader ? (
								<Box display="inline-block" ml={2}>
									<CircularProgress size={14} />
								</Box>
							) : (
								<Link component="button" onClick={handleClick} variant="body2">
									click here to resend it
								</Link>
							)}
							.
						</Typography>
					</Box>
				</Stack>
			</Card>
		</Wrapper>
	);
};
